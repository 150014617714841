import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea, Link } from "@mui/material";

interface ActionCardProps {
    imageUrl?: string;
    imageAlt?: string;
    header: string;
    body: string;
    link: string;
}

export default function ActionCard({
    imageUrl,
    imageAlt,
    header,
    body,
    link,
}: ActionCardProps) {
    return (
        <Card sx={{ maxWidth: 345, minWidth: 300, minHeight: 200, margin: 1 }}>
            <CardActionArea sx={{ height: "100%" }}>
                <Link href={link} underline="none">
                    {imageUrl && (
                        <CardMedia
                            component="img"
                            height="140"
                            image={imageUrl}
                            alt={imageAlt}
                        />
                    )}
                    <CardContent>
                        <Typography
                            gutterBottom
                            variant="h5"
                            component="div"
                            color="text.primary"
                        >
                            {header}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {body}
                        </Typography>
                    </CardContent>
                </Link>
            </CardActionArea>
        </Card>
    );
}
