import { Link, Typography } from '@mui/material';
import { routePaths } from '../routes/routes';

export const Error = () => {
    return (
        <>
            <Typography variant="h4">Error</Typography>
            <Typography variant="h5">
                This page doesn't exist! Click{' '}
                <Link href={routePaths.ACTIVITIES}>here</Link> to go back home
            </Typography>
        </>
    );
};
