import {
    List,
    ListItem,
    Paper,
    Typography,
    useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import rosieLogo from "../../assets/RosieLabLogo.png";

export const AboutUs = () => {
    const mobile = useMediaQuery("(max-width:700px)");

    return (
        <Paper sx={{ padding: "2rem" }}>
            <Typography variant="h4">About Us</Typography>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: mobile ? "column" : "row",
                }}
            >
                <Box
                    sx={{
                        width: mobile ? "100%" : "24%",
                        marginRight: "2rem",
                        marginTop: mobile ? "2rem" : "5%",
                        maxHeight: "50%",
                        maxWidth: "33rem",
                        minWidth: "13rem",
                    }}
                >
                    <img
                        width="710"
                        height="207"
                        style={{ width: "100%", height: "auto" }}
                        src={rosieLogo}
                        alt="rosie lab logo"
                    />
                </Box>
                <Box>
                    <Typography
                        variant="h5"
                        sx={{ margin: "1.5rem 0", fontWeight: "bold" }}
                    >
                        Robots with Social Intelligence and Empathy (ROSIE) Lab
                    </Typography>
                    <Typography variant="body1" sx={{ maxWidth: "45rem" }}>
                        The ROSIE Lab is led by Dr. Angelica Lim, Assistant
                        Professor of Professional Practice in the School of
                        Computing Science at Simon Fraser University. One of the
                        main goals of ROSIE Lab is to build robots that are
                        useful and can interact naturally and seamlessly with
                        humans. ROSIE Lab also works on developing smart AI
                        software to help robots understand what humans do,
                        think, feel, and mean through AI algorithms and
                        implementing models of the human mind.{" "}
                    </Typography>

                    <List>
                        <Typography sx={{ fontWeight: "bold" }}>
                            Team Leaders:
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{ marginBottom: "1rem", fontWeight: 400 }}
                        >
                            Dr. Angelica Lim : Principal Investigator
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{ marginBottom: "1rem", fontWeight: 400 }}
                        >
                            Paige Tuttösí : PhD Candidate
                        </Typography>
                        <Typography sx={{ fontWeight: "bold" }}>
                            Research Assistants:
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{ marginBottom: "1rem", fontWeight: 400 }}
                        >
                            Lina Dang-Nguyen (September 2021 to April 2022)
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{ marginBottom: "1rem", fontWeight: 400 }}
                        >
                            Julie Jen (September 2021 to April 2022)
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{ marginBottom: "1rem", fontWeight: 400 }}
                        >
                            Eric Liu (September 2021 to April 2022)
                        </Typography>
                    </List>
                </Box>
            </Box>
        </Paper>
    );
};
