import { Container } from "@mui/material";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import NavBar from "./commons/components/NavBar.component";
import { routes } from "./commons/routes/routes";
import ReactGA from "react-ga";
import { useEffect } from "react";
import { Error } from "./commons/pages/Error";

const TRACKING_ID = "UA-219203005-1";
ReactGA.initialize(TRACKING_ID);

const App = () => {
    useEffect(() => {
        ReactGA.pageview("Main Website");
    }, []);

    return (
        <Router>
            <NavBar />
            <Container
                sx={{
                    padding: { xs: 2, md: 5 },
                    minHeight: "85vh",
                    width: "100vw",
                    display: "flex",
                    flexDirection: "column",
                }}
                className="App"
            >
                <Switch>
                    {routes.map(({ path, component }) => {
                        return (
                            <Route
                                exact
                                key={path}
                                path={path}
                                component={component}
                            />
                        );
                    })}
                    <Route path='/404' component={Error} />
                    <Redirect from='*' to='/404' />
                </Switch>
            </Container>
        </Router>
    );
};

export default App;
