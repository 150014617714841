import Activities from "../../commons/pages/HomePage";
import { LearnMore } from "../pages/LearnMore";
import { AboutUs } from "../pages/AboutUs";

export const routePaths = {
    ACTIVITIES: "/",
    TEACHER_VOICE: "/teacherVoice",
    LEARN_MORE: "/learnMore",
    ABOUT_US: "/aboutUs",
};

export const routes = [
    {
        path: routePaths.ACTIVITIES,
        component: Activities,
    },
    {
        path: routePaths.TEACHER_VOICE,
    },
    {
        path: routePaths.LEARN_MORE,
        component: LearnMore,
    },
    {
        path: routePaths.ABOUT_US,
        component: AboutUs,
    },
];
