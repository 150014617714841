import { List, ListItem, Paper, Typography } from "@mui/material";

export const LearnMore = () => {
    return (
        <Paper sx={{ padding: "2rem" }}>
            <Typography variant="h4" sx={{ marginBottom: "2rem" }}>
                Learn More
            </Typography>
            <Typography variant="body1" sx={{ maxWidth: "55rem" }}>
                Nowwithfeeling is a collaborative site for psychologists and
                researchers to collect affective information around the globe
                through fun activities. Emotion can be expressed in different
                ways depending on cultural, context, and individual differences.
                Collecting affective information will help us understand more
                about how people express and interpret emotions!
            </Typography>
            <Typography
                variant="h6"
                sx={{ marginTop: "2rem", fontWeight: "bold" }}
            >
                Examples of affective research using this data are follows:
            </Typography>
            <List>
                <ListItem sx={{ display: "list-item" }}>
                    <Typography
                        variant="body1"
                        sx={{ marginBottom: "1rem", fontWeight: 400 }}
                    >
                        - Building context specific synthetic voices.
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{ marginBottom: "1rem", fontWeight: 400 }}
                    >
                        - Building robots to help us learn more efficiently.
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{ marginBottom: "1rem", fontWeight: 400 }}
                    >
                        - Understanding how people express emotions with varying
                        loudness.
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{ marginBottom: "1rem", fontWeight: 400 }}
                    >
                        - Modifying text to speech voices based on emojis
                        present in the text.
                    </Typography>
                </ListItem>
            </List>
        </Paper>
    );
};
